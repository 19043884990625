<!-- components/EventList.vue -->
<template>
  <div class="container">
    <div v-for="(dayEvents, date) in eventsToDisplay" :key="date" class="date">
      <h2 class="day-name text-2xl md:text-4xl font-bold uppercase">{{ formatDateHeader(date) }}</h2>
      <ul class="flex flex-col event-list mt-4">
        <EventItem v-for="event in dayEvents" :key="event.id" :event="event" :hoveredSlug="hoveredSlug"
          :handleMouseEnter="handleMouseEnter" :handleMouseLeave="handleMouseLeave" />
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isToday, isTomorrow } from "date-fns";
import { toZonedTime, format } from "date-fns-tz";
import { useDateFormat } from '@vueuse/core';

const props = defineProps({
  page: {
    type: Number,
    default: 0,
  },
  events: {
    type: Array,
    required: true,
  },
  useInternalPagination: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:page']);

const page = ref(props.page);
const today = startOfDay(new Date());
const startDate = ref(addDays(today, page.value * 7));
const endDate = computed(() => addDays(startDate.value, 6));

function startOfDay(date) {
  const copy = new Date(Number(date));
  copy.setHours(0, 0, 0, 0);
  return copy;
}

function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

const eventsToDisplay = computed(() => {
  if (!props.useInternalPagination) {
    return getEventsByDay(props.events, new Date(-8640000000000000), new Date(8640000000000000));
  }
  return getEventsByDay(props.events, startDate.value, endDate.value);
});

function getEventsByDay(events, start, end) {
  const groupedEvents = {};
  events.forEach(event => {
    const eventDate = new Date(event.startDate);
    if (eventDate >= start && eventDate <= end) {
      const date = event.startDate.split('T')[0];
      if (!groupedEvents[date]) {
        groupedEvents[date] = [];
      }
      groupedEvents[date].push(event);
    }
  });
  return Object.keys(groupedEvents).sort((a, b) => new Date(a) - new Date(b)).reduce((acc, date) => {
    acc[date] = groupedEvents[date];
    return acc;
  }, {});
}

watch(() => props.page, (newPage) => {
  page.value = newPage;
  startDate.value = addDays(today, newPage * 7);
});

const formatDateHeader = (date) => {
  const zonedDate = toZonedTime(date, "America/Toronto");
  if (isToday(zonedDate)) {
    return 'Today';
  } else if (isTomorrow(zonedDate)) {
    return 'Tomorrow';
  } else {
    return format(zonedDate, 'EEEE, MMMM d');
  }
};

const formatTime = (date) => useDateFormat(date, 'h:mm a').value;

const nextPage = () => {
  page.value += 1;
  emit('update:page', page.value);
};

const prevPage = () => {
  page.value -= 1;
  emit('update:page', page.value);
};

const day = (date) => useDateFormat(date, "MMMM D").value;
const hour = (date) => useDateFormat(date, "h:mma").value;

let hoveredSlug = ref(-1);

const handleMouseEnter = (index) => {
  hoveredSlug.value = index;
};

const handleMouseLeave = () => {
  hoveredSlug.value = -1;
};

onMounted(() => {
  const initializeGSAP = async () => {
    const gsap = (await import('gsap')).default;
    const { ScrollTrigger } = await import('gsap/ScrollTrigger');
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('.date').forEach((item, index) => {
      gsap.fromTo(item,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: item,
            start: 'top 90%',
            toggleActions: 'play none none none',
          },
          duration: .5,
          delay: index * 0.2 // Stagger the animation for each item
        }
      );
    });
  };

  initializeGSAP();
});



</script>

<style scoped>
.date {
  @apply mt-12 md:mt-16 lg:mt-24;

  &:first-child {
    @apply mt-0
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.event-list h3 {
  font-family: 'GT Alpina Trial Cn Th';
  list-style: none;
  padding: 0;
}

.hover-div {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  padding: 1rem;
  width: 100%;
  min-height: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  z-index: 100;
  pointer-events: none;
}

.vertical-text-left {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}

.vertical-text-right {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.grow-enter-active,
.grow-leave-active {
  transition: none;
}

.grow-enter-from,
.grow-leave-to {
  opacity: 0;
}

.grow-enter-to,
.grow-leave-from {
  opacity: 1;
}

.group,
.hover-div {
  cursor: pointer;
}
</style>
